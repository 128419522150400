import profile from "../assets/profile.png";
import logo from "../assets/logo.png";
import signature from "../assets/signature.png";

const images = {
  profile,
  logo,
  signature,
};

export default images;
