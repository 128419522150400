import React, { useState } from "react";
import "./SocialMediaSticky.scss";
import {
  AiFillLinkedin,
  AiFillGithub,
  AiOutlineMessage,
  AiOutlineMail,
  AiOutlinePhone,
} from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";

const SocialMediaSticky = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="media__container app__flex">
        <motion.div
          whileInView={{ opacity: [0, 1] }}
          transition={{ duration: 0.9 }}
          className="media__container app__flex"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <motion.div
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
            className="app__flex main-icon"
          >
            <AiOutlineMail />
          </motion.div>
        </motion.div>
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.div
              layout
              initial={{ x: 90, opacity: 0 }}
              animate={{ x: 110, opacity: 1 }}
              exit={{ x: 90, opacity: 0 }}
              transition={{ duration: 1 }}
              className="app__flex contact-icons"
            >
              <a
                href="https://www.linkedin.com/in/dawid-krupa-dev"
                target="_blank"
                rel="noreferrer"
              >
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.3 }}
                  className="app__flex"
                >
                  <AiFillLinkedin />
                </motion.div>
              </a>

              <a
                href="https://github.com/davidkrupa"
                target="_blank"
                rel="noreferrer"
              >
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.3 }}
                  className="app__flex"
                >
                  <AiFillGithub />
                </motion.div>
              </a>

              <a href="mailto:dawidkrupadev@gmail.com">
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.3 }}
                  className="app__flex"
                >
                  <AiOutlineMail />
                </motion.div>
              </a>

              <a href="tel:+31 686 414 321">
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.3 }}
                  className="app__flex"
                >
                  <AiOutlinePhone />
                </motion.div>
              </a>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default SocialMediaSticky;
