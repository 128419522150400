import React from "react";
import { motion } from "framer-motion";

import { AppWrap } from "../../wrapper";
import images from "../../constants/images";
import "./Header.scss";

const Header = () => {
  return (
    <div className="app__header app__flex">
      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.9 }}
        className="app__header-img"
      >
        <motion.div
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.5 }}
          className="app__header-container app__flex"
        >
          <img src={images.profile} alt="profile" />
        </motion.div>
      </motion.div>

      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.9, delay: 0.2 }}
        className="app__header-info"
      >
        <div className="signature">
          <img src={images.signature} alt="signature" />
          {/* <h1>Dawid Krupa</h1> */}
          <p>- Front End Developer -</p>
        </div>
      </motion.div>
    </div>
  );
};

export default AppWrap(Header, "home");
