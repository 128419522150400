import React, { useState, useEffect } from "react";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import { AnimatePresence, motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Work.scss";

const Work = () => {
  const [filterWork, setFilterWork] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [projectsDemos, setProjectsDemos] = useState([]);
  const [currentDemo, setCurrentDemo] = useState({});

  useEffect(() => {
    const projectsQuery = '*[_type == "works"]';
    const projectsDemosQuery = '*[_type == "demos"]';

    client.fetch(projectsQuery).then((data) => {
      setFilterWork(data);
    });

    client.fetch(projectsDemosQuery).then((data) => {
      setProjectsDemos(data);
    });
  }, []);

  const handleOpenWorkDetail = (title) => {
    const demo = projectsDemos.filter((current) => current.projectName === title);
    setCurrentDemo(demo[0]);

    if (isOpen) {
      setIsOpen(false);
      setTimeout(() => {
        setIsOpen(true);
      }, 1250);
    } else setIsOpen(true);
  };

  const handleCloseWorkDetail = () => {
    setIsOpen(false);
    setCurrentDemo({});
  };

  return (
    <>
      <h2 className="head-text">
        My<span> Projects</span>
      </h2>

      <motion.div
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWork.map((work, index) => (
          <div
            className={`app__work-item app__flex ${
              work.title === currentDemo.projectName ? "active" : null
            }`}
            onClick={
              work.title === currentDemo.projectName
                ? () => handleCloseWorkDetail()
                : () => handleOpenWorkDetail(work.title)
            }
            key={index}
          >
            <div className="app__work-img app__flex">
              <img src={urlFor(work.imgUrl)} alt={work.name} />

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.25,
                  ease: "easeInOut",
                  staggerChildren: 0.5,
                }}
                className="app__work-hover app__flex"
              >
                <a href={work.projectLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>
                <a href={work.codeLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillGithub />
                  </motion.div>
                </a>
              </motion.div>
            </div>

            <div className="app__work-content app__flex">
              <h4 className="bold-text">{work.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>
                {work.description}
              </p>
              {/* Work tag when filter is on */}
              {/* <div className='app__work-tag app__flex'>
                <p className='p-text'>{work.tags[0]}</p>
              </div> */}
            </div>
            {work.title === currentDemo.projectName ? (
              <button onClick={() => handleCloseWorkDetail()} className="hide">
                Hide Demo
              </button>
            ) : (
              <button onClick={() => handleOpenWorkDetail(work.title)}>Show Demo</button>
            )}
          </div>
        ))}
      </motion.div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            layout
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 1.5 }}
            className="app__flex app__work-demo-container"
          >
            {currentDemo?.details?.map((item, index) => (
              <div className="app__flex section" key={`project-${index}`}>
                <div className="demo-image-container">
                  <img src={urlFor(item.imgurl)} alt={item.title} />
                </div>
                <div className="demo-content-container">
                  <h4 className="bold-text">{item.title}</h4>
                  <p className="p-text">{item.paragraph}</p>
                </div>
              </div>
            ))}
            {/* <button onClick={() => handleCloseWorkDetail()}>Hide Demo</button> */}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default AppWrap(MotionWrap(Work, "app__works"), "work", "app__whitebg");
