import React, { useState } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { AiFillLinkedin, AiFillGithub, AiOutlinePhone } from "react-icons/ai";

import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";
import "./Footer.scss";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: "contact",
      name: name,
      email: email,
      message: message,
    };

    client.create(contact).then(() => {
      setLoading(false);
      setIsFormSubmitted(true);
    });
  };

  return (
    <>
      <h2 className="head-text">Let's talk</h2>
      <div className="app__footer-cards">
        <div className="app__footer-card">
          <div className="app__flex contact-icon">
            <HiOutlineMail />
          </div>
          <a href="mailto:dawidkrupadev@gmail.com" className="p-text">
            dawidkrupadev@gmail.com
          </a>
        </div>

        <div className="app__footer-card">
          <div className="app__flex contact-icon">
            <AiOutlinePhone />
          </div>
          <a href="tel:+31 686 414 321" className="p-text">
            +31 686 414 321
          </a>
        </div>

        <div className="app__footer-card">
          <div className="app__flex contact-icon">
            <AiFillLinkedin />
          </div>
          <a
            href="https://www.linkedin.com/in/dawid-krupa-dev"
            target="_blank"
            rel="noreferrer"
            className="p-text"
          >
            linkedin.com/in/dawid-krupa-dev
          </a>
        </div>

        <div className="app__footer-card">
          <div className="app__flex contact-icon">
            <AiFillGithub />
          </div>
          <a
            href="https://github.com/davidkrupa"
            target="_blank"
            rel="noreferrer"
            className="p-text"
          >
            github.com/davidkrupa
          </a>
        </div>
      </div>

      {/* {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input
              className="p-text"
              type="text"
              placeholder="Your Name"
              name="name"
              value={name}
              onChange={handleChangeInput}
            />
          </div>
          <div className="app__flex">
            <input
              className="p-text"
              type="email"
              placeholder="Your Email"
              name="email"
              value={email}
              onChange={handleChangeInput}
            />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your Message"
              name="message"
              value={message}
              onChange={handleChangeInput}
            />
          </div>
          <button
            type="button"
            className="p-text"
            onClick={() => handleSubmit()}
          >
            {loading ? "Sending..." : "Send Message"}
          </button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">Thank you for your message!</h3>
        </div>
      )} */}
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__whitebg"
);
